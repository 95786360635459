@media only screen and (max-width:992px) {
    .HeaderLarge {
        display: none;
    }
}

@media only screen and (min-width:992px) {
    .HeaderLarge {
        position: relative;
        display: initial;
    }

    .HeaderBgLarge {
        display: flex;
        padding-top: 1rem;
        background-color: #1d4e4b;
        justify-content: flex-end;
    }

    .HeaderMenuLarge {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 3rem;
        justify-content: center;
        justify-content: flex-end;
        box-shadow: #ddd 0.4px 2px 5px;
        padding-block: 1.5rem;
        background-color: #ffffff;
        z-index: 5;
      }
    
      .HeaderMenuLarge-withoutScroll {
        position: relative;
      }
      .HeaderMenuLarge-onscroll {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        animation: slideDown 0.35s ease-out;
      }
    
      @keyframes slideDown {
        from {
          transform: translateY(-100%);
        }
        to {
          transform: translateY(0);
        }
      }

    .CallNowLarge {
        width: 10%;
        background-color: #f79723;
        display: flex;
        align-items: center;
        border-top-left-radius: 10px;
        justify-content: center;
        padding: 0.5rem;
        margin-right: 1rem;
    }

    .SearchFieldLarge {
        font-family: 'Poppins-Regular';
        border-radius: 2px;
        border: none;
        box-shadow: 5px 2px 10px 1px rgb(0 0 0 / 14%);
        outline: none;
        padding: 0.5rem;
        width: 15%;
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
        margin-left: 2rem;
        width:200px
    }

    .SearchFieldLarge:focus {
        outline: 0.4px solid #1d4e4b;
    }

    .SearchIconLarge {
        background-color: #1d4e4b;
        align-items: center;
        display: flex;
        padding-inline: 0.5rem;
        padding-block: 0.35rem;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }

    .NavigationMenu {
        display: flex;
        align-items: flex-end;
    }

    .NavigationMenuTitle {
        margin: 0;
        font-family: 'Montserrat-SemiBold';
    }

    .NavigationMenuTitle:hover {
        color: #f79723;
        cursor: pointer;
    }

    .NavigationMenuTitleContainer {
        margin-inline: 2rem;
    }

    .CartIconLarge {
        display: flex;
        position:relative;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2rem;
        margin-right: 4rem;
    }

    /* .CartIconLarge:hover+.cart-floating
    {
        display: flex;
        flex-direction: column;
        animation: fadeInAnimation ease 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    } */

    .headerLogoLarge {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        margin-left: 1rem;
    }

    .cart-floating-container {
        position: absolute;
        padding-top: 1rem;
        right: -3rem;
        top: 1.5rem;
        min-width: 400px;
        max-height: 600px;
        z-index: 5;
        animation: fadeInAnimation ease 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

    }

    .cart-floating {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #ffffff;
        padding: 1rem;
        border-radius: 7px;
        -webkit-box-shadow: 0px 12px 35px -4px rgba(0, 0, 0, 0.59);
        -moz-box-shadow: 0px 12px 35px -4px rgba(0, 0, 0, 0.59);
        box-shadow: 0px 12px 35px -4px rgba(0, 0, 0, 0.59);
        max-height: 400px;
        overflow: scroll;
    }

    .cart-no-item {
        flex: 1;
        align-items: center;
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 7px;
        -webkit-box-shadow: 0px 12px 35px -4px rgba(0, 0, 0, 0.59);
        -moz-box-shadow: 0px 12px 35px -4px rgba(0, 0, 0, 0.59);
        box-shadow: 0px 12px 35px -4px rgba(0, 0, 0, 0.59);
    }

    .view-cart-button {
        align-self: flex-end;
        background-color: #1d4e4b;
        padding-inline: 1rem;
        padding-block: 0.5rem;
        border-radius: 32px
    }

    .view-cart-button:hover {
        background-color: #f79723;
        cursor: pointer;
    }

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .fab {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        width: 80px;
        height: 80px;
        cursor: pointer;
        z-index: 5;
    }

    
}