.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.SpinnerContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url(./fonts/Poppins-Light.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(./fonts/Poppins-Medium.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'BeVietnamPro-Regular';
  src: local('BeVietnamPro-Regular'), url(./fonts/BeVietnamPro-Regular.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(./fonts/Montserrat-SemiBold.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(./fonts/Montserrat-Medium.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@media only screen and (max-width:800px) {
  .routerContainer {
    margin-bottom: 5rem;
  }
}