.containerHeaderMain {
    display: flex;
    align-items: 'center';
    padding-block: 2rem;
    justify-content: center;
}

.routeWrapper {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.route-names {
    font-family: 'Poppins-Regular';
    font-size: 20px;
    flex-wrap: 'nowrap';
    margin:0;
    cursor: pointer;
}
.route-names:hover{
    color:#f79723
}

.CallNowCommon {
    background-color: #f79723;
    display: flex;
    align-items: center;
    border-top-left-radius: 10px;
    justify-content: center;
    padding: 0.2rem;
}

.drawer-icon {
    margin-left: 1rem;
    justify-content: 'center';
    display: flex;
    align-items: center;
}

.whatsapp-header {
    justify-content: 'center';
    align-items: 'center';
}

@media only screen and (min-width:992px) {
    .whatsapp-header {
        display: none;
    }

    .CallNowCommon {
        display: none;
    }

    .drawer-icon {
        display: none;
    }

    .routeWrapper {
        justify-content: flex-start;
        margin-left: 2rem;
    }
}