.HeaderBg {
    justify-content: center;
    /* background-color: #1d4e4b; */
    display: flex;
    flex: 1;
    align-items: center;
}

.CallNow {

    background-color: #f79723;
    display: flex;

    align-items: center;
    border-top-left-radius: 10px;
    justify-content: center;
    padding: 0.2rem;

}

.HeaderMenu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.SearchField {
    font-family: 'Poppins-Regular';
    border-radius: 2px;
    border: none;
    box-shadow: 5px 2px 10px 1px rgb(0 0 0 / 14%);
    outline: none;
}
.SearchField:focus{
    outline:0.4px solid #1d4e4b;
}

.drawerIconHeaderRoot {
    display: 'flex';
    flex: 0.2;
    justify-content: 'center';
    align-items: 'center';
    opacity: 0.8;
    margin-left: 1rem;
}

.drawerIcon:hover {
    opacity: 1;
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: box-shadow;
}

.HeaderRoot{
    margin-block: 1rem;
}

@media only screen and (min-width:992px) {
    .HeaderRoot{
        display: none;
    }
}